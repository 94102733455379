import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "wizard" ]
 
  connect() {
    this.setupWizard()
  }

  disconnect() {
    $(this.wizardTarget).wizard("destroy")
  }

  setupWizard() {
    if (this.hasWizardTarget) {
      // var validate = {
      //   errorPlacement: function( error, element ) {
      //     if ( element.is( ":radio" ) || element.is( ":checkbox" ) ) {
      //       error.insertBefore( element.next() );
      //     } else {
      //       error.insertAfter( element );
      //     }
      //   }
      // };
      $(this.wizardTarget).wizard({
        enableSubmit: true,
        afterForward: function(event, state) {
          if (state.isLastStep) {
            $(".wizard-submit").show()
            $(".forward").hide()
          } 
        },
        afterBackward: function(event, state) {
          $(".wizard-submit").hide()
          $(".forward").show()
        },
        // beforeForward: function(event, state) {
        //   if (state.stepIndex === 2) {
        //     $('input[name="profile[treatment_answer]"]:checked').length > 0;
        //   } else if (state.stepIndex === 3) {
        //     $('input[name="profile[biomarker_answer]"]:checked').length > 0;
        //   } else if (state.stepIndex === 4) {
        //     $('input[name="profile[therapy_answer]"]:checked').length > 0;
        //   }
        //   return $(this).valid();
        // }
        create: function(event, state) {
          $(".wizard-submit").hide()
        }
      })/* .validate( validate )*/
    }
  }
}
