import { Controller } from "stimulus"

export default class extends Controller {
  startTour() {
    Turbolinks.visit('/learn', 'replace')
    setTimeout( () => {
      hopscotch.startTour(learnTour)
    }, 500)
  }
}
