import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "picker", "field" ]
 
  connect() {
    this.setupPicker()
    this.setDate()
  }

  setupPicker() {
    const today = new Date()
    $(this.pickerTarget).datepicker()
  }

  setDate() {
    $(this.pickerTarget).on('changeDate', () => {
      $(this.fieldTarget).val(
        $(this.pickerTarget).datepicker('getFormattedDate')
      )
    })
  }
}
