import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['carousel']

  connect() {
    this.setupOwlCarousel()
  }

  disconnect() {
    $(this.carouselTarget).owlCarousel('destroy');
  }


  setupOwlCarousel() {
    $(this.carouselTarget).owlCarousel({
      margin:40,
      dots:true,
      nav:true,
      navText: [
        "<svg class='nav-chevron'><use xlink:href='/img/sprites/base.svg#chevron-left' xmlns:xlink='http://www.w3.org/1999/xlink'></use></svg>",
        "<svg class='nav-chevron'><use xlink:href='/img/sprites/base.svg#chevron-right' xmlns:xlink='http://www.w3.org/1999/xlink'></use></svg>"
      ],
      responsive:{
        0:{
          items: 1,
          slideBy: 1
        },
        767:{
          items: 2,
          margin:30,
          slideBy: 2,
          nav:true
        },
        1023: {
          items: 3,
          slideBy: 3
        }
      }
    })
  }
}
