import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "alert", "survey", "video" ]

  //- STIMULUS LIFECYCLE

  connect() {
    this.setupPlayer()
    this.setupPlayerLifecycle()
  }

  disconnect() {
    if (this.data.get('status') == 'started') {
      this.updateViewership('position', jwplayer('educationModule').getPosition())
    }
  }

  //- JWPLAYER

  setupPlayer() {
    var player = jwplayer('educationModule')
    var status = this.data.get('status')

    player.setup({
      file: `//content.jwplatform.com/videos/${this.data.get('videoId')}.mp4`,
      width: '100%',
    })
    
    if (this.videoIncomplete(status)) {
      if (this.data.get('position')) {
        player.seek(this.data.get('position'))
      }
    }
  }

  setupPlayerLifecycle() {
    var player = jwplayer('educationModule')
    var status = this.data.get('status')

    // If this is the first time viewing the video, 
    // change the status from 'not started' to 'started'

    player.on('play', () => {
      if (status == 'not started') {
        this.updateStatus('started')
      }
    })

    // If this video has never been completed, 
    // change the status to 'video finished' if there is a survey, 
    // and 'finished' if there is no survey 

    player.on('complete', () => {
      // if the video isn't finished, we want to complete it correctly
      if (this.videoIncomplete(status)) {
        if (this.hasSurveyTarget) {
          this.swapVideoForSurvey()
          this.updateStatus('video finished')
        } else {
          this.updateStatus('finished')
        }
      } else if (status == 'video finished') {
        // if the video is already finished, 
        // but a survey remains, be sure to load the survey
        if (this.hasSurveyTarget) {
          this.swapVideoForSurvey()
        }
      }
    })

    player.on('time', (event) => {
      if (event.position > event.duration - 30) {
        this.toggleAlert()
      }
    })
  }

  //- PRIVATE

  videoIncomplete(status) {
    return (status != 'finished' || status != 'video finished')
  }

  updateStatus(status) {
    this.updateViewership('status', status)
    this.data.set('status', status)
  }

  updateViewership(key, value) {
    var data = new FormData()
    data.append(`viewership[${key}]`, value)
    Rails.ajax({
      type: 'PATCH',
      url: `/learn/${this.data.get("id")}`,
      data: data
    })
  }

  swapVideoForSurvey() {
    if (this.hasSurveyTarget) {
      this.toggleSurvey()
      this.toggleVideo()
    }
  }

  toggleSurvey() {
    this.surveyTarget.classList.toggle('hidden')
  }

  toggleVideo() {
    this.videoTarget.classList.toggle('hidden')
  }

  toggleAlert() {
    if (this.hasAlertTarget) {
      if (this.alertTarget.classList.contains('hidden')) {
        this.alertTarget.classList.toggle('hidden')
      }
    }
  }
}
