import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "modal" ]
 
  connect() {
    this.setupModal()
  }

  setupModal() {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal({
        backdrop: 'static',
        keyboard: false
      })
    }
  }
}
